import "./SocialMediaContainer.css";
const SocialMediaContainer = () => {
  return (
    <div className="frame22">
      <div className="divcopyright9">
        <i className="rnfi-services-pvt9">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
      <div className="footer5">
        <div className="divrow21">
          <div className="divcol-xl-310">
            <div className="divhelp-desk5">
              <div className="divf-heading30">
                <div className="pseudo41" />
                <div className="sales-helpdesk5">Sales Helpdesk</div>
              </div>
              <div className="frame23">
                <div className="link12">
                  <img className="vector-icon33" alt="" src="images/vector25.svg" />
                  <div className="div14">8560 900 500</div>
                </div>
                <div className="link13">
                  <img className="vector-icon34" alt="" src="images/vector26.svg" />
                  <div className="salesrnfiservicescom5">
                    {" "}
                    sales.com
                  </div>
                </div>
              </div>
            </div>
            <div className="frame24">
              <div className="divf-heading30">
                <div className="pseudo41" />
                <div className="head-office5">Head Office</div>
              </div>
              <div className="list34">
                <div className="item67">
                  <img className="vector-icon35" alt="" src="images/vector27.svg" />
                  <div className="p18">
                    <div className="ug-5-MT-PORTAL-house-container10">
                      <span className="ug-5-MT-PORTAL-house-container11">
                        <p className="ug-5-MT-PORTAL-house5">
                          UG-5, MT-PORTAL House, Plot No. 42,
                        </p>
                        <p className="ug-5-MT-PORTAL-house5">
                          DLF Industrial Area, Kirti Nagar,
                        </p>
                        <p className="ug-5-MT-PORTAL-house5">
                          New Delhi - 110015, India
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="item68">
                  <img className="vector-icon36" alt="" src="images/vector28.svg" />
                  <div className="link-0115">011 4920 7777</div>
                </div>
                <div className="item69">
                  <img className="vector-icon37" alt="" src="images/vector26.svg" />
                  <div className="link-supportrnfiservicesco5">
                    support.com
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divcol-xl-65">
            <div className="divf-heading32">
              <div className="pseudo41" />
              <div className="services5">Services</div>
            </div>
            <div className="divrow22">
              <div className="list35">
                <div className="item-link75">Travel Services</div>
                <div className="item-link76">PAN Card</div>
                <div className="item-link77">ITR Filing</div>
              </div>
              <div className="frame25">
                <div className="list36">
                  <div className="item-link78">Money Transfer</div>
                  <div className="item-link79">Aadhaar Banking</div>
                  <div className="item-link80">Micro ATM</div>
                  <div className="item-link81">Utility Payments</div>
                </div>
                <div className="divcol-md-65">
                  <div className="list37">
                    <div className="item-link82">API</div>
                    <div className="item-link83">POS</div>
                  </div>
                  <div className="divf-heading33">
                    <div className="pseudo41" />
                    <div className="products5">Products</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divcol-xl-311">
            <div className="list38">
              <div className="item-link84">Home</div>
              <div className="item-link85">About</div>
              <div className="item-link86">Contact us</div>
              <div className="item-link87">{`Terms & Conditions`}</div>
              <div className="item-link88">Privacy Policy</div>
              <div className="item-link89">Corporate Social Responsibility</div>
            </div>
            <div className="divf-heading34">
              <div className="pseudo41" />
              <div className="our-company5">Our Company</div>
            </div>
            <div className="divf-heading35">
              <div className="pseudo41" />
              <div className="follow-us-on5">Follow us on</div>
            </div>
            <img className="frame-icon5" alt="" src="images/frame4.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaContainer;
