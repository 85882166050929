import React, { useState } from "react";
import { useCallback } from "react";
import FormContainer from "../components/FormContainer";
import ContainerFooter from "../components/ContainerFooter";
import { useNavigate } from "react-router-dom";
import "./ITRFiling.css";
import Header from "../components/Header.js";
const ITRFiling = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  
   // State to control the visibility of list14
   const [isListVisible, setListVisible] = useState(false);

   // Function to toggle the visibility of list14
   const toggleListVisibility = () => {
     setListVisible(!isListVisible);
   };

      // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  return (
    <div className="itr-filing">
      <Header />
      <div className="section">
        <FormContainer />
      </div>
      <ContainerFooter
        dimensionCode="images/vector.svg"
        dimensionCodeText="images/vector1.svg"
        dimensionCodeImageUrl="images/vector2.svg"
        dimensionCodeText2="images/vector2.svg"
        dimensionCodeImageUrl2="images/vector3.svg"
        dimensionCodeImageUrl3="images/divsocialicon.svg"
        dimensionCodeText3="images/vector4.svg"
        dimensionCodeText4="images/vector5.svg"
        dimensionCodeImageUrl4="images/component-1.svg"
        dimensionCodeText5="images/vector6.svg"
      />
      <div className="divcopyright">
        <i className="rnfi-services-pvt">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
    </div>
  );
};

export default ITRFiling;
