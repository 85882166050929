import "./MoneyTransferForm.css";
const MoneyTransferForm = () => {
  return (
    <div className="section8">
      <div className="divrow15">
        <div className="divcol-sm-1212">
          <div className="divbanner-text6">
            <div className="nav-breadcrumb-ordered-lis6">
              <div className="item-products6">{`Products & Solutions`}</div>
              <div className="item53">
                <div className="item-products6">/</div>
                <div className="money-transfer1">Money Transfer</div>
              </div>
            </div>
            <b className="heading-26">Money Transfer</b>
            <div className="p14">
              <i className="money-transfer-is-container">
                <p className="money-transfer-is">
                  Money transfer is a crucial and basic need in our day-to-day
                  lives. At MT-PORTAL we
                  hope to empower people belonging to the rural and migrant
                  segment of our
                  country by ensuring that this basic need is expertly and
                  conveniently met with the
                  help of our remittance services.
                </p>
              </i>
            </div>
            <div className="p15">
              <i className="money-transfer-is-container">
                <p className="money-transfer-is">
                  We offer both Domestic and Indo-Nepal money transfer services.
                  Our Domestic
                  Money Transfer services cater to the needs of the rural
                  segment and our Indo-
                  Nepal money transfer services cater to the needs of the
                  Nepalese migrants in our country.</p>
              </i>
            </div>
            <div className="p16">
              <i className="money-transfer-is-container">
                <p className="money-transfer-is">
                  We have simplified and streamlined the process of money
                  transfers for the
                  convenience of our customers. Now all the customer has to do
                  is visit their closest
                  MT-PORTAL-associated merchant to carry out their transactions.
                  With an ever-
                  expanding network of merchants, we are rapidly eliminating the
                  need of going to
                  the bank for our customers, especially in rural areas.
                </p>
              </i>
            </div>
          </div>
        </div>
        <div className="divcol-sm-1213">
          <img
            className="money-transfer-bannerpng-icon"
            alt=""
            src="images/moneytransferbannerpng@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default MoneyTransferForm;
