import React, { useState } from "react";
import { useCallback } from "react";
import PanCardServicesContainer from "../components/PanCardServicesContainer";
import { useNavigate } from "react-router-dom";
import ContainerFooter from "../components/ContainerFooter";
import "./PanCardServices.css";
const PanCardServices = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);


  // State to control the visibility of list14
  const [isListVisible, setListVisible] = useState(false);

  // Function to toggle the visibility of list14
  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  return (
    <div className="pan-card-services">
      <PanCardServicesContainer />
      <div className="divcopyright1">
        <i className="rnfi-services-pvt1">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
      <div className="header1">
        <div className="divnavbar-brand1">
          <img className="link-logopng1" alt="" src="images/link--logopng@2x.png" />
        </div>
        <div className={`nav1 ${isListVisible ? "display-block" : ""}`}>
        <img
            className="close-icon"
            src="images/close.png"
            alt="Close Sidebar"
            onClick={closeSidebar}
          />
          <div className="list1">
            <div className="item5">
              <i className="link-home1" onClick={onLinkHomeClick}>Home</i>
            </div>
            <div className="item6">
              <i className="link-about1" onClick={onLinkAboutClick}>
                About RNFI
              </i>
            </div>
            <div className="item7">
              <i className="link-home1">{`Products & Solutions`}</i>
              <div className="mega-menu">
                <div className="menu-column">
                  <h2 className="menu-heading">Products</h2>
                  <ul className="mega-menu-list">
                    <li><a href="aadhar-banking">Aadhar Card</a></li>
                    <li><a href="micro-atm">Micro ATM</a></li>
                    <li><a href="money-transfer">Money Transfer</a></li>
                  </ul>
                </div>
                <div className="menu-column">
                  <h2 className="menu-heading">Solutions</h2>
                  <ul className="mega-menu-list">
                    <li><a href="Utilities">Utilities</a></li>
                    <li><a href="travel-services">Travel</a></li>
                    <li><a href="itrfiling">ITRFiling</a></li>
                    <li><a href="pan-card-services">Pan Card</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item8">
              <i className="link-about1" onClick={onLinkContactClick}>
                Contact Us
              </i>
            </div>
            <div className="item9">
              <div className="pseudo1" />
              <i className="link-login1">Login</i>
            </div>
          </div>
        </div>
        <div className="mobile-toggle3" onClick={toggleListVisibility}>
          <img className="hamburger" src="images/hamburger.png" alt="Toggle Menu" />
        </div>
      </div>
      <ContainerFooter
        dimensionCode="images/vector7.svg"
        dimensionCodeText="images/vector8.svg"
        dimensionCodeImageUrl="images/vector9.svg"
        dimensionCodeText2="images/vector9.svg"
        dimensionCodeImageUrl2="images/vector10.svg"
        dimensionCodeImageUrl3="images/divsocialicon1.svg"
        dimensionCodeText3="images/vector11.svg"
        dimensionCodeText4="images/vector12.svg"
        dimensionCodeImageUrl4="images/component-11.svg"
        dimensionCodeText5="images/vector13.svg"
        salesrnfiservicescomZIndex="unset"
        salesrnfiservicescomMarginTop="unset"
        salesrnfiservicescomPosition="absolute"
        salesrnfiservicescomTop="43.56em"
        salesrnfiservicescomLeft="-0.25em"
      />
    </div>
  );
};

export default PanCardServices;
