import { useMemo } from "react";
import "./ProductContainer.css";
const ProductContainer = ({
  dimensionCode,
  dimensionCodeText,
  dimensionCodeImageUrl,
  dimensionCodeText2,
  dimensionCodeImageUrl2,
  dimensionCodeImageUrl3,
  dimensionCodeText3,
  dimensionCodeText4,
  dimensionCodeImageUrl4,
  dimensionCodeText5,
  pseudoTop,
  pseudoLeft,
  pseudoHeight,
  pseudoTop1,
}) => {
  const footer1Style = useMemo(() => {
    return {
      top: pseudoTop,
      left: pseudoLeft,
      height: pseudoHeight,
    };
  }, [pseudoTop, pseudoLeft, pseudoHeight]);

  const vectorIconStyle = useMemo(() => {
    return {
      top: pseudoTop1,
    };
  }, [pseudoTop1]);

  return (
    <div className="footer3" style={footer1Style}>
      <div className="divrow10">
        <div className="divcol-xl-36">
          <div className="list21">
            <div className="item42">
              <img className="vector-icon18" alt="" src={"images/" + dimensionCode} />
              <div className="p7">
                <div className="ug-5-MT-PORTAL-house-container6">
                  <span className="ug-5-MT-PORTAL-house-container7">
                    <p className="ug-5-MT-PORTAL-house3">
                      UG-5, MT-PORTAL House, Plot No. 42,
                    </p>
                    <p className="ug-5-MT-PORTAL-house3">
                      DLF Industrial Area, Kirti Nagar,
                    </p>
                    <p className="ug-5-MT-PORTAL-house3">
                      New Delhi - 110015, India
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="item43">
              <img className="vector-icon19" alt="" src={"images/" + dimensionCodeText} />
              <div className="link-0113">011 4920 7777</div>
            </div>
            <div className="item44">
              <img
                className="vector-icon20"
                alt=""
                src={"images/" + dimensionCodeImageUrl}
              />
              <div className="link-supportrnfiservicesco3">
                support.com
              </div>
            </div>
          </div>
          <div className="divf-heading18">
            <div className="pseudo24" />
            <div className="head-office3">Head Office</div>
          </div>
          <div className="divhelp-desk3">
            <div className="link6">
              <img
                className="vector-icon21"
                alt=""
                src={"images/" + dimensionCodeText2}
                style={vectorIconStyle}
              />
              <div className="salesrnfiservicescom3">
                {" "}
                sales.com
              </div>
            </div>
            <div className="link7">
              <img
                className="vector-icon22"
                alt=""
                src={"images/" + dimensionCodeImageUrl2}
              />
              <div className="div6">8560 900 500</div>
            </div>
            <div className="divf-heading19">
              <div className="pseudo24" />
              <div className="sales-helpdesk3">Sales Helpdesk</div>
            </div>
          </div>
        </div>
        <div className="divcol-xl-37">
          <div className="list22">
            <div className="item-link45">Home</div>
            <div className="item-link46">About</div>
            <div className="item-link47">Contact us</div>
            <div className="item-link48">{`Terms & Conditions`}</div>
            <div className="item-link49">Privacy Policy</div>
            <div className="item-link50">Corporate Social Responsibility</div>
          </div>
          <img
            className="divsocial-icon1"
            alt=""
            src={dimensionCodeImageUrl3}
          />
          <div className="divf-heading20">
            <div className="pseudo24" />
            <div className="our-company3">Our Company</div>
          </div>
          <div className="divf-heading21">
            <div className="pseudo24" />
            <div className="follow-us-on3">Follow us on</div>
          </div>
          <img className="vector-icon23" alt="" src={"images/" + dimensionCodeText3} />
          <img
            className="component-1-icon1"
            alt=""
            src={"images/" + dimensionCodeImageUrl4}
          />
        </div>
        <div className="divcol-xl-63">
          <div className="divf-heading22">
            <div className="pseudo24" />
            <div className="services3">Services</div>
          </div>
          <div className="divrow11">
            <div className="list23">
              <div className="item-link51">Money Transfer</div>
              <div className="item-link52">Aadhaar Banking</div>
              <div className="item-link53">Micro ATM</div>
              <div className="item-link54">Utility Payments</div>
            </div>
            <div className="list24">
              <div className="item-link55">Travel Services</div>
              <div className="item-link56">PAN Card</div>
              <div className="item-link57">ITR Filing</div>
            </div>
            <div className="divcol-md-63">
              <div className="list25">
                <div className="item-link58">API</div>
                <div className="item-link59">POS</div>
              </div>
              <div className="divf-heading23">
                <div className="pseudo24" />
                <div className="products3">Products</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductContainer;
