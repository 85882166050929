import { useMemo } from "react";
import "./FollowUsContainer.css";
const FollowUsContainer = ({ itemLinkTop, itemLinkHeight }) => {
  const footer2Style = useMemo(() => {
    return {
      top: itemLinkTop,
      height: itemLinkHeight,
    };
  }, [itemLinkTop, itemLinkHeight]);

  return (
    <div className="footer4" style={footer2Style}>
      <div className="divrow16">
        <div className="divcol-xl-38">
          <div className="divhelp-desk4">
            <div className="divf-heading24">
              <div className="pseudo31" />
              <div className="sales-helpdesk4">Sales Helpdesk</div>
            </div>
            <div className="frame17">
              <div className="link8">
                <img className="vector-icon26" alt="" src="images/vector10.svg" />
                <div className="div11">8560 900 500</div>
              </div>
              <div className="link9">
                <img className="vector-icon27" alt="" src="images/vector9.svg" />
                <div className="salesrnfiservicescom4">
                  {" "}
                  sales.com
                </div>
              </div>
            </div>
          </div>
          <div className="frame18">
            <div className="divf-heading24">
              <div className="pseudo31" />
              <div className="head-office4">Head Office</div>
            </div>
            <div className="list27">
              <div className="item54">
                <img className="vector-icon28" alt="" src="images/vector7.svg" />
                <div className="p17">
                  <div className="ug-5-MT-PORTAL-house-container8">
                    <span className="ug-5-MT-PORTAL-house-container9">
                      <p className="ug-5-MT-PORTAL-house4">
                        UG-5, MT-PORTAL House, Plot No. 42,
                      </p>
                      <p className="ug-5-MT-PORTAL-house4">
                        DLF Industrial Area, Kirti Nagar,
                      </p>
                      <p className="ug-5-MT-PORTAL-house4">
                        New Delhi - 110015, India
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div className="item55">
                <img className="vector-icon29" alt="" src="images/vector8.svg" />
                <div className="link-0114">011 4920 7777</div>
              </div>
              <div className="item56">
                <img className="vector-icon30" alt="" src="images/vector9.svg" />
                <div className="link-supportrnfiservicesco4">
                  support.com
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divcol-xl-64">
          <div className="divf-heading26">
            <div className="pseudo31" />
            <div className="services4">Services</div>
          </div>
          <div className="divrow17">
            <div className="list28">
              <div className="item-link60">Travel Services</div>
              <div className="item-link61">PAN Card</div>
              <div className="item-link62">ITR Filing</div>
            </div>
            <div className="frame19">
              <div className="list29">
                <div className="item-link63">Money Transfer</div>
                <div className="item-link64">Aadhaar Banking</div>
                <div className="item-link65">Micro ATM</div>
                <div className="item-link66">Utility Payments</div>
              </div>
              <div className="divcol-md-64">
                <div className="list30">
                  <div className="item-link67">API</div>
                  <div className="item-link68">POS</div>
                </div>
                <div className="divf-heading27">
                  <div className="pseudo31" />
                  <div className="products4">Products</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divcol-xl-39">
          <div className="list31">
            <div className="item-link69">Home</div>
            <div className="item-link70">About</div>
            <div className="item-link71">Contact us</div>
            <div className="item-link72">{`Terms & Conditions`}</div>
            <div className="item-link73">Privacy Policy</div>
            <div className="item-link74">Corporate Social Responsibility</div>
          </div>
          <div className="divf-heading28">
            <div className="pseudo31" />
            <div className="our-company4">Our Company</div>
          </div>
          <div className="divf-heading29">
            <div className="pseudo31" />
            <div className="follow-us-on4">Follow us on</div>
          </div>
          <img className="frame-icon2" alt="" src="images/frame.svg" />
        </div>
      </div>
    </div>
  );
};

export default FollowUsContainer;
