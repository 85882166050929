import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigationType, useLocation } from 'react-router-dom';
import ITRFiling from './pages/ITRFiling';
import PanCardServices from './pages/PanCardServices';
import TravelServices from './pages/TravelServices';
import Utilities from './pages/Utilities';
import MicroATM from './pages/MicroATM';
import AadharBanking from './pages/AadharBanking';
import MoneyTransfer from './pages/MoneyTransfer';
import ContactUs from './pages/ContactUs';
import AboutRNFI from './pages/AboutRNFI';
import Homepage from './pages/Homepage';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = 'Home';
        metaDescription = 'Welcome to our website.';
        break;
      case '/pan-card-services':
        title = 'Pan Card Services';
        metaDescription = 'Explore our Pan Card services.';
        break;
      case '/travel-services':
        title = 'Travel Services';
        metaDescription = 'Discover our Travel services.';
        break;
      case '/utilities':
        title = 'Utilities';
        metaDescription = 'Explore our Utilities section.';
        break;
      case '/micro-atm':
        title = 'Micro ATM';
        metaDescription = 'Learn about our Micro ATM services.';
        break;
      case '/aadhar-banking':
        title = 'Aadhar Banking';
        metaDescription = 'Explore Aadhar-based banking services.';
        break;
      case '/money-transfer':
        title = 'Money Transfer';
        metaDescription = 'Discover easy money transfer services.';
        break;
      case '/contact-us':
        title = 'Contact Us';
        metaDescription = 'Get in touch with our team.';
        break;
      case '/about-rnfi':
        title = 'About RNFI';
        metaDescription = 'Learn about our company - RNFI.';
        break;
      case '/itrfiling':
        title = 'ITR Filing';
        metaDescription = 'File your income tax returns with ease.';
        break;
      default:
        title = 'Page Not Found';
        metaDescription = 'The requested page was not found.';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="homepage" element={<Homepage />} />
      <Route path="pan-card-services" element={<PanCardServices />} />
      <Route path="travel-services" element={<TravelServices />} />
      <Route path="utilities" element={<Utilities />} />
      <Route path="micro-atm" element={<MicroATM />} />
      <Route path="aadhar-banking" element={<AadharBanking />} />
      <Route path="money-transfer" element={<MoneyTransfer />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="about-rnfi" element={<AboutRNFI />} />
      <Route path="itrfiling" element={<ITRFiling />} />
    </Routes>
  );
}

export default App;