import Property1Default from "./Property1Default";
import "./ConnectWithUsContainer.css";
const ConnectWithUsContainer = () => {
  return (
    <div className="section9">
      <div className="divrow18">
        <div className="divcol-sm-1214">
          <div className="divbanner-text7">
            <b className="heading-31">Connect with us!</b>
            <div className="frame20">
              <img
                className="contact-imgpng-icon"
                alt=""
                src="images//contactimgpng@2x.png"
              />
              <div className="nav-breadcrumb-ordered-lis7">
                <div className="item-have">Have a query?</div>
              </div>
            </div>
          </div>
        </div>
        <div className="divcol-sm-1215">
          <div className="form">
            <div className="divform-row">
              <div className="divform-group" />
              <div className="divform-group1" />
              <div className="divform-group2" />
              <div className="divform-group3" />
              <div className="divform-group4" />
              <div className="form1">
          <div className="divform-row2">
            <div className="divform-group11">
              <div className="component-4">
                <label className="label-mobile1">Mobile</label>
                <input type="text"></input>
              </div>
            </div>
            <div className="divform-group12">
              <div className="options2">
                <div className="div15">
                <select className="dropdown-select2">State
                    <option value="option1">State</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="newoption">New Option</option>
                </select>
                </div>

              </div>
            </div>
            <div className="component-3">
              <i className="label-name1">Name</i>
              <input type="text"></input>
            </div>
            <div className="divform-group13">
              <div className="input" />
              <div className="div16"></div>
              <i className="label-email1">Email</i>
              <input type="email"></input>
            </div>
            <div className="divform-group14">
              <div className="input" />
              <i className="label-message1">Message</i>
              <textarea></textarea>
            </div>
            <div className="component-51">
              <div className="options4">
                <div className="div15">
                <select className="dropdown-select2">Select Type
                    <option value="option1">Select Type</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    <option value="newoption">New Option</option>
                </select>
                </div>
              </div>
            </div>
          </div>
          <div className="button">
            <div className="submit1">Submit</div>
          </div>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWithUsContainer;
