import "./ServicesContainer.css";
const ServicesContainer = () => {
  return (
    <div className="section13">
      <div className="divrow27">
        <div className="divcol-sm-1218">
          <div className="divservices">
            <div className="heading-42">DMT</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Domestic Money Transfer (DMT)
                which is an assisted service to
                facilitate money transfer needs
                of unbanked, under-banked and
                migrant people in India.
              </p>
            </div>
          </div>
          <img
            className="money-transaction-6065499-2-icon"
            alt=""
            src="images/moneytransaction-6065499-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1219">
          <div className="divservices1">
            <div className="heading-42">AEPS</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Aadhaar card holders can
                seamlessly make financial
                transactions, through which you
                can, make withdrawals, make
                enquiry about bank balance.
              </p>
            </div>
          </div>
          <img
            className="ipad-1523436-2-icon"
            alt=""
            src="images/ipad-1523436-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1220">
          <div className="divservices2">
            <div className="heading-42">Pan Card Service</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Now get a new PAN card made
                or get an existing one updated
                through authorized channels.
                With strong and secure network.
              </p>
            </div>
          </div>
          <img
            className="tablets-484703-2-icon"
            alt=""
            src="images/tablets-484703-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1221">
          <div className="divservices3">
            <div className="heading-42">Insurance</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Safeguard your customers from
                the uncertainties of life. Protect
                them with affordable insurance
                plans across health, medical and
              </p>
              <p className="domestic-money-transfer">general insurance.</p>
            </div>
          </div>
          <img
            className="png-transparent-tax-document-t-icon"
            alt=""
            src="images/pngtransparenttaxdocumenttaxfiletaxreporttaxpapertaxdocument3dicon-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1222">
          <div className="divservices4">
            <div className="heading-42">EMI Collection</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Customer can pay loan amount for all major NBFCs.</p>
            </div>
          </div>
          <img
            className="trolley-cart-3114401-2-icon"
            alt=""
            src="images/trolleycart-3114401-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1223">
          <div className="divservices5">
            <div className="heading-42">Micro ATM</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                A local kirana store can become
                an atm centers that can provide
                basic atm services, accept
                payments from customers with {`Debit & Credit cards.`}</p>
            </div>
          </div>
          <img
            className="atm-5720118-2-icon"
            alt=""
            src="images/atm-5720118-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1224">
          <div className="divservices6">
            <div className="heading-48">
              <div className="mobile-and-dth-container">
                <p className="mobile-and-dth">Mobile and DTH</p>
                <p className="recharge">recharge</p>
              </div>
            </div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Recharge of different mobile
                networks and DTH connection
                service is provided from our
                portal, like Airtel, Vodafone, Idea,
                Tata Sky, Videocon, Airtel DTH, Sun etc.</p>
            </div>
          </div>
          <img
            className="tablets-484703-2-icon"
            alt=""
            src="images/money-1790213-2@2x.png"
          />
        </div>
        <div className="divcol-sm-1225">
          <div className="divservices7">
            <div className="heading-42">Payment gateway</div>
            <div className="domestic-money-transfer-container">
              <p className="domestic-money-transfer">
                Payment gateway is an interface
                to do online transactions. By
                payment gateway, the customer
                can avail credit card, debit card
                or net banking services at the retailers shop.</p>
            </div>
          </div>
          <img
            className="utilities-8250092-2-icon"
            alt=""
            src="images/utilities-8250092-2@2x.png"
          />
        </div>
      </div>
      <div className="label-left1">Our Services</div>
    </div>
  );
};

export default ServicesContainer;
