import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./RevolutionaryFintechContainer.css";
const RevolutionaryFintechContainer = () => {
  const navigate = useNavigate();

  const onItemLink16Click = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const onItemLink17Click = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  return (
    <div className="section12">
      <div className="divrow26">
        <div className="divbanner-text9">
          <div className="nav-breadcrumb-ordered-lis9">
            <div className="item-we">We are MT-PORTAL</div>
          </div>
          <div className="heading-28">
            <b className="revolutionary-fintech-solution-container">
              <p className="revolutionary-fintech">Revolutionary Fintech</p>
              <p className="revolutionary-fintech">solutions for growth and</p>
              <p className="revolutionary-fintech">acceleration</p>
            </b>
          </div>
          <div className="p20">
            <i className="at-rnfi-services-container">
              <span className="at-rnfi-services-container1">
                <p className="revolutionary-fintech">
                  At MT-PORTAL Pvt Ltd, we strive to simplify digital
                  payments to make them
                  more convenient, reliable, and accessible for all, ensuring
                  that location is not a limiting factor.</p>
              </span>
            </i>
          </div>
        </div>
        <img
          className="divcarouselexampleindicators-icon"
          alt=""
          src="images/divcarouselexampleindicators.svg"
        />
        <div className="link16">
          <i className="read-more">{`Read more `}</i>
          <img className="vector-icon54" alt="" src="images/vector42.svg" />
        </div>

      </div>
    </div>
  );
};

export default RevolutionaryFintechContainer;
