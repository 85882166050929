import "./PartnerCard1.css";
const PartnerCard1 = () => {
  return (
    <div className="div21">
      <div className="divcontainer">
        <b className="our-partners">Our Partners</b>
        <div className="divregular">
          <div className="divslick-list">
            <div className="divslick-track">
              <div className="divslide-img">
                <img className="finopng-icon" alt="" src="images/finopng@2x.png" />
              </div>
              <div className="divslide-img1">
                <img className="finopng-icon" alt="" src="images/paytmpng@2x.png" />
              </div>
              <div className="divslide-img2">
                <img
                  className="finopng-icon"
                  alt=""
                  src="images/razorpaypng@2x.png"
                />
              </div>
              <div className="divslide-img3">
                <img
                  className="just-rechargepng-icon"
                  alt=""
                  src="images/justrechargepng@2x.png"
                />
              </div>
              <div className="divslide-img4">
                <img
                  className="finopng-icon"
                  alt=""
                  src="images/indicorepng@2x.png"
                />
              </div>
              <div className="divslide-img5">
                <img className="finopng-icon" alt="" src="images/airtelpng@2x.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard1;