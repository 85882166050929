import "./AadhaarBankingForm.css";
const AadhaarBankingForm = () => {
  return (
    <div className="section7">
      <div className="divrow14">
        <div className="divcol-sm-1210">
          <div className="divbanner-text5">
            <div className="frame16">
              <div className="nav-breadcrumb-ordered-lis5">
                <div className="item-products5">{`Products & Solutions`}</div>
                <div className="item52">
                  <div className="item-products5">/</div>
                  <div className="aadhaar-banking">Aadhaar Banking</div>
                </div>
              </div>
              <b className="heading-25">Aadhaar Banking</b>
              <div className="p12">
                <i className="we-have-taken-container">
                  <p className="we-have-taken">
                    We have taken a huge leap towards promoting the government’s
                    attempts
                    towards the promotion of financial inclusion in India, with
                    the help of our Aadhar banking services.</p>
                </i>
              </div>
            </div>
            <div className="p13">
              <i className="we-have-taken-container">
                <p className="we-have-taken">
                  Now services such as Cash Withdrawal, Balance Enquiry,
                  Mini-statements,
                  Aadhaar Pay and Cash Deposits can be availed by the customers
                  through MT-PORTAL
                  platform. Our Aadhar banking services, don’t just benefit the
                  customers but also
                  the retailers in rural areas. The retailers will earn a
                  commission every time they
                  carry out a transaction for a customer, thus generating an
                  alternative source of income for them.</p>
              </i>
            </div>
          </div>
        </div>
        <div className="divcol-sm-1211">
          <img
            className="aadhaar-bankingpng-icon"
            alt=""
            src="images//aadhaarbankingpng@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default AadhaarBankingForm;