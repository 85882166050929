import "./AboutUsCard.css";
const AboutUsCard = () => {
  return (
    <div className="section10">
      <div className="divrow19">
        <div className="divcol-sm-1216">
          <div className="divbanner-text8">
            <div className="nav-breadcrumb-ordered-lis8">
              <div className="item-who">Who we are</div>
            </div>
            <b className="heading-27">About Us</b>
            <div className="ph2d-46eb9809">
              <i className="india-is-currently-container">
                <p className="india-is-currently">
                  India is currently the powerhouse of fintech innovations and
                  with many fintechs
                  focusing on the rapid advancement of cutting-edge payment
                  products we have
                  become the market leader in processing digital transactions.
                  Despite this, there is
                  a large volume of India’s population that still doesn’t have
                  access to basic banking
                  facilities. We have decided to change that. With MT-PORTAL, we
                  aim to not only create
                  revolutionary financial solutions but also bridge the gap of
                  technological
                  inaccessibility between rural and urban India.
                </p>
              </i>
            </div>
          </div>
        </div>
        <div className="divcol-sm-1217">
          <img className="aboutpng-icon" alt="" src="images//aboutpng@2x.png" />
        </div>
      </div>
    </div>
  );
};

export default AboutUsCard;