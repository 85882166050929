import "./TravelServicesContainer.css";
const TravelServicesContainer = () => {
  return (
    <div className="section4">
      <div className="divrow9">
        <div className="divcol-sm-124">
          <div className="divbanner-text2">
            <div className="nav-breadcrumb-ordered-lis2">
              <div className="item-products2">{`Products & Solutions`}</div>
              <div className="item41">
                <div className="item-products2">/</div>
                <div className="travel-services1">Travel Services</div>
              </div>
            </div>
            <b className="heading-22">Travel Services</b>
            <div className="p6">
              <i className="MT-PORTAL-offers-a-container">
                <p className="MT-PORTAL-offers-a">
                  MT-PORTAL offers a variety travel services such as train and
                  flight ticket bookings at
                  reasonably priced and comprehensive rates. Now instead of
                  dealing with an
                  unknown travel agent, customers can comfortably visit their
                  trusted MT-PORTAL-
                  associated merchant and get the best deals on their travel and
                  train ticket
                  bookings. Our travel ticket booking service is backed by a
                  team of responsive
                  customer care executives for guidance and convenient query
                  resolutions for our retailers.</p>
              </i>
            </div>
          </div>
        </div>
        <div className="divcol-sm-125">
          <img
            className="travel-servicepng-icon"
            alt=""
            src="images/travelservicepng@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default TravelServicesContainer;
