import React, { useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./ContainerFrameMicroATM.css";
import Header from "../components/Header.js";
const ContainerFrameMicroATM = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  // State to control the visibility of list14
  const [isListVisible, setListVisible] = useState(false);

  // Function to toggle the visibility of list14
  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

    // Function to close the sidebar
    const closeSidebar = () => {
      setListVisible(false); // Set isListVisible to false to close the sidebar
    };

  return (
    <div className="frame14">
      <Header />
      <div className="section6">
        <div className="divrow13">
          <div className="divcol-sm-128">
            <div className="divbanner-text4">
              <div className="frame15">
                <div className="nav-breadcrumb-ordered-lis4">
                  <div className="item-products4">{`Products & Solutions`}</div>
                  <div className="item46">
                    <div className="item-products4">/</div>
                    <div className="micro-atm1">Micro ATM</div>
                  </div>
                </div>
                <b className="heading-24">Micro ATM</b>
              </div>
              <div className="p10">
                <i className="since-atms-have-container">
                  <p className="since-atms-have">
                    Since ATMs have a high setup cost and are only financially
                    viable in places that
                    have a high footfall, it is extremely difficult to find ATMs
                    in rural areas where even
                    the population is below the minimum required footfall.
                  </p>
                </i>
              </div>
              <div className="p11">
                <i className="since-atms-have-container">
                  <p className="since-atms-have">
                    Now with the help of MT-PORTAL’s micro-ATM service, bank
                    account holders can
                    access the services offered by a regular ATM just by
                    visiting their closest MT-PORTAL-
                    associated merchant. Since the set-up cost of a micro-ATM is
                    extremely low, even
                    small merchants can set up a micro-ATM and carry out
                    transactions for their
                    customers and earn a generous commission.
                  </p>
                </i>
              </div>
            </div>
          </div>
          <div className="divcol-sm-129">
            <img
              className="micro-atmpng-icon"
              alt=""
              src="images/microatmpng@2x.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerFrameMicroATM;
