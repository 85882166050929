import "./PartnerCard.css";
const PartnerCard = () => {
  return (
    <div className="divrow20">
      <div className="divcol-6">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/umangpng@2x.png" />
        </div>
      </div>
      <div className="divcol-61">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/meitypng@2x.png" />
        </div>
      </div>
      <div className="divcol-62">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/finobankpng@2x.png" />
        </div>
      </div>
      <div className="divcol-63">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/nsdlpng@2x.png" />
        </div>
      </div>
      <div className="divcol-64">
        <div className="divpart-img">
          <img
            className="umangpng-icon"
            alt=""
            src="images/bankindialogopng@2x.png"
          />
        </div>
      </div>
      <div className="divcol-65">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/unionbankpng@2x.png" />
        </div>
      </div>
      <div className="divcol-66">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/axisbankpng@2x.png" />
        </div>
      </div>
      <div className="divcol-67">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/icicibankpng@2x.png" />
        </div>
      </div>
      <div className="divcol-68">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/bankofbarodapng@2x.png" />
        </div>
      </div>
      <div className="divcol-69">
        <div className="divpart-img">
          <img
            className="umangpng-icon"
            alt=""
            src="images/paytmpaymentbankpng@2x.png"
          />
        </div>
      </div>
      <div className="divcol-610">
        <div className="divpart-img">
          <img
            className="umangpng-icon"
            alt=""
            src="images/airtelpaymentbankpng@2x.png"
          />
        </div>
      </div>
      <div className="divcol-611">
        <div className="divpart-img">
          <img
            className="umangpng-icon"
            alt=""
            src="images/bharatbillpaypng@2x.png"
          />
        </div>
      </div>
      <div className="divcol-612">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/irctclogopng@2x.png" />
        </div>
      </div>
      <div className="divcol-613">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/proteanpng@2x.png" />
        </div>
      </div>
      <div className="divcol-614">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/mobikwikpng@2x.png" />
        </div>
      </div>
      <div className="divcol-615">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/blinkitlogopng@2x.png" />
        </div>
      </div>
      <div className="divcol-616">
        <div className="divpart-img">
          <img className="umangpng-icon" alt="" src="images/ltlogopng@2x.png" />
        </div>
      </div>
      <div className="divcol-617">
        <div className="divpart-img17">
          <img
            className="au-bank-logopng-icon"
            alt=""
            src="images/aubanklogopng@2x.png"
          />
        </div>
      </div>
      <div className="divcol-618">
        <div className="divpart-img18">
          <img
            className="delhivery-logopng-icon"
            alt=""
            src="images/delhiverylogopng@2x.png"
          />
        </div>
      </div>
      <div className="divcol-619">
        <div className="divpart-img17">
          <img
            className="au-bank-logopng-icon"
            alt=""
            src="images/ecomexpresspng@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
