import "./PanCardServicesContainer.css";
const PanCardServicesContainer = () => {
  return (
    <div className="section3">
      <div className="divrow8">
        <div className="divcol-sm-122">
          <div className="divbanner-text1">
            <div className="nav-breadcrumb-ordered-lis1">
              <div className="item-products1">{`Products & Solutions`}</div>
              <div className="item40">
                <div className="item-products1">/</div>
                <div className="pan-card-services1">Pan Card Services</div>
              </div>
            </div>
            <b className="heading-21">Pan Card Services</b>
            <div className="p4">
              <i className="in-an-attempt-container">
                <p className="in-an-attempt">
                  In an attempt to make government services more accessible
                  MT-PORTAL has
                  integrated pan card application services on its platform in
                  association with
                  Protean E Gov services. Any MT-PORTAL associate merchant can now
                  help their
                  customers carry out their PAN Card application or
                  rectification and generate an additional source of revenue.</p>
              </i>
            </div>
            <div className="p5">
              <i className="in-an-attempt-container">
                <p className="in-an-attempt">
                  Now individual customers wouldn’t have to trouble themselves
                  with finding a
                  particular merchant or government centre to carry out their
                  pan card-related
                  needs and can instead go to their closest MT-PORTAL-associated
                  merchant and get
                  the job done swiftly and conveniently.
                </p>
              </i>
            </div>
          </div>
        </div>
        <div className="divcol-sm-123">
          <img
            className="pan-servicepng-icon"
            alt=""
            src="images/panservicepng@2x.png"
          />
        </div>
      </div>
    </div>
  );
};

export default PanCardServicesContainer;
