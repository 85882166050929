import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Header1.css";

const Header1 = () => {
  const navigate = useNavigate();

  const [isListVisible, setListVisible] = useState(false);

  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  const onLinkHomeClick = () => {
    navigate("/homepage");
  };

  const onLinkContactClick = () => {
    navigate("/contact-us");
  };

  return (
    <div className="header8">
      <div className="divnavbar-brand8">
        <img
          className="link-logopng8"
          alt=""
          src="images/link--logopng3@2x.png"
        />
      </div>
      <div className="nav8">
        <div className={`list33 ${isListVisible ? "display-block" : ""}`}>
        <img
            className="close-icon"
            src="images/close.png"
            alt="Close Sidebar"
            onClick={closeSidebar}
          />
          <div className="item62">
            <i className="link-home8" onClick={onLinkHomeClick}>
              Home
            </i>
          </div>
          <div className="item63">
            <div className="link11">
              <div className="pseudo39" />
              <div className="about-rnfi1">About RNFI</div>
            </div>
          </div>
          <div className="menu-container">
          <div className="item-link-products-solut1 item64">
            <i className="link-products8">{`Products & Solutions`}</i>
              <div className="mega-menu">
                <div className="menu-column">
                  <h2 className="menu-heading">Products</h2>
                  <ul className="mega-menu-list">
                    <li><a href="aadhar-banking">Aadhar Card</a></li>
                    <li><a href="micro-atm">Micro ATM</a></li>
                    <li><a href="money-transfer">Money Transfer</a></li>
                  </ul>
                </div>
                <div className="menu-column">
                  <h2 className="menu-heading">Solutions</h2>
                  <ul className="mega-menu-list">
                    <li><a href="Utilities">Utilities</a></li>
                    <li><a href="travel-services">Travel</a></li>
                    <li><a href="itrfiling">ITRFiling</a></li>
                    <li><a href="pan-card-services">Pan Card</a></li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          <div className="item65">
            <i className="link-home8" onClick={onLinkContactClick}>
              Contact Us
            </i>
          </div>
          <div className="item66">
            <div className="pseudo40" />
            <i className="link-login8">Login</i>
          </div>
        </div>
        <div className="mobile-toggle" onClick={toggleListVisibility}>
          <img className="hamburger" src="images/hamburger.png"></img>
        </div>
      </div>
    </div>
  );
};

export default Header1;
