import AboutUsCard from "../components/AboutUsCard";
import Header1 from "../components/Header1";
import PartnerCard from "../components/PartnerCard";
import SocialMediaContainer from "../components/SocialMediaContainer";
import "./AboutRNFI.css";
const AboutRNFI = () => {
  return (
    <div className="about-rnfi">
      <div className="frame11">
        <AboutUsCard />
        <Header1 />
      </div>
      <div className="section1">
        <b className="what-we-do">What We DO</b>
        <div className="divrow4">
          <div className="divcol-sm-6">
            <div className="divabout-sec">
              <img
                className="figure-visionpng"
                alt=""
                src="images/figure--visionpng@2x.png"
              />
              <div className="frame12">
                <div className="ph2d-5a23c12f">
                  <i className="to-become-the-container">
                    <p className="to-become-the">
                      To become the leading financial solutions provider on the
                      market by
                      providing avant-garde yet simplified and seamless
                      financial solutions.
                    </p>
                  </i>
                </div>
                <b className="heading-4">Vision</b>
              </div>
            </div>
          </div>
          <div className="divcol-sm-61">
            <div className="divabout-sec1">
              <img
                className="figure-missionpng"
                alt=""
                src="images/figure--missionpng@2x.png"
              />
              <div className="frame13">
                <div className="ph2d-982cb5a">
                  <i className="to-become-the-container">
                    <p className="to-become-the">
                      To empower rural India by promoting the accessibility of
                      financial
                      technology with simple and efficient financial solutions,
                      thus aiding the
                      development of a digital BHARAT.
                    </p>
                  </i>
                </div>
                <b className="heading-4">Mission</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section2">
        <b className="heading-3">Our Partners</b>
        <div className="divcss-nmvfuy">
          <PartnerCard />
        </div>
      </div>
      <SocialMediaContainer />
    </div>
  );
};

export default AboutRNFI;
