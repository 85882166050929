import React, { useState } from "react";
import { useCallback } from "react";
import TravelServicesContainer from "../components/TravelServicesContainer";
import { useNavigate } from "react-router-dom";
import ProductContainer from "../components/ProductContainer";
import "./TravelServices.css";
const TravelServices = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  
  // State to control the visibility of list14
  const [isListVisible, setListVisible] = useState(false);

  // Function to toggle the visibility of list14
  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  return (
    <div className="travel-services">
      <TravelServicesContainer />
      <div className="header2">
        <div className="divnavbar-brand2">
          <img className="link-logopng2" alt="" src="images/link--logopng@2x.png" />
        </div>
        <div className={`nav2 ${isListVisible ? "display-block" : ""}`}>
        <img
            className="close-icon"
            src="images/close.png"
            alt="Close Sidebar"
            onClick={closeSidebar}
          />
          <div className="list2">
            <div className="item10">
              <i className="link-home2" onClick={onLinkHomeClick}>Home</i>
            </div>
            <div className="item11">
              <i className="link-about2" onClick={onLinkAboutClick}>
                About RNFI
              </i>
            </div>
            <div className="item12">
              <i className="link-home2">{`Products & Solutions`}</i>
              <div className="mega-menu">
                <div className="menu-column">
                  <h2 className="menu-heading">Products</h2>
                  <ul className="mega-menu-list">
                    <li><a href="aadhar-banking">Aadhar Card</a></li>
                    <li><a href="micro-atm">Micro ATM</a></li>
                    <li><a href="money-transfer">Money Transfer</a></li>
                  </ul>
                </div>
                <div className="menu-column">
                  <h2 className="menu-heading">Solutions</h2>
                  <ul className="mega-menu-list">
                    <li><a href="Utilities">Utilities</a></li>
                    <li><a href="travel-services">Travel</a></li>
                    <li><a href="itrfiling">ITRFiling</a></li>
                    <li><a href="pan-card-services">Pan Card</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item13">
              <i className="link-about2" onClick={onLinkContactClick}>
                Contact Us
              </i>
            </div>
            <div className="item14">
              <div className="pseudo2" />
              <i className="link-login2">Login</i>
            </div>
          </div>
        </div>
        <div className="mobile-toggle3" onClick={toggleListVisibility}>
          <img className="hamburger" src="images/hamburger.png" alt="Toggle Menu" />
        </div>
      </div>
      <ProductContainer
        dimensionCode="/vector7.svg"
        dimensionCodeText="/vector8.svg"
        dimensionCodeImageUrl="/vector9.svg"
        dimensionCodeText2="/vector9.svg"
        dimensionCodeImageUrl2="/vector10.svg"
        dimensionCodeImageUrl3="images/divsocialicon1.svg"
        dimensionCodeText3="/vector11.svg"
        dimensionCodeText4="/vector12.svg"
        dimensionCodeImageUrl4="/component-11.svg"
        dimensionCodeText5="/vector13.svg"
      />

      <div className="divcopyright2">
        <i className="rnfi-services-pvt2">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
    </div>
  );
};

export default TravelServices;
