import "./ProductFooter.css";
const ProductFooter = () => {
  return (
    <div className="footer6">
      <div className="divrow24">
        <div className="divcol-xl-312">
          <div className="list39">
            <div className="item70">
              <img className="vector-icon46" alt="" src="images/vector35.svg" />
              <div className="p19">
                <div className="ug-5-MT-PORTAL-house-container12">
                  <p className="ug-5-MT-PORTAL-house6">
                    UG-5, MT-PORTAL House, Plot No. 42,
                  </p>
                  <p className="ug-5-MT-PORTAL-house6">
                    DLF Industrial Area, Kirti Nagar,
                  </p>
                  <p className="ug-5-MT-PORTAL-house6">
                    New Delhi - 110015, India
                  </p>
                </div>
              </div>
            </div>
            <div className="item71">
              <img className="vector-icon47" alt="" src="images/vector36.svg" />
              <div className="link-0116">011 4920 7777</div>
            </div>
            <div className="item72">
              <img className="vector-icon48" alt="" src="images/vector37.svg" />
              <div className="link-supportrnfiservicesco6">
                support.com
              </div>
            </div>
          </div>
          <div className="divf-heading36">
            <div className="pseudo48" />
            <div className="head-office6">Head Office</div>
          </div>
          <div className="divhelp-desk6">
            <div className="link14">
              <img className="vector-icon49" alt="" src="images/vector37.svg" />
              <div className="salesrnfiservicescom6">
                {" "}
                sales.com
              </div>
            </div>
            <div className="link15">
              <img className="vector-icon50" alt="" src="images/vector38.svg" />
              <div className="div20">8560 900 500</div>
            </div>
            <div className="divf-heading37">
              <div className="pseudo48" />
              <div className="sales-helpdesk6">Sales Helpdesk</div>
            </div>
          </div>
        </div>
        <div className="divcol-xl-313">
          <div className="list40">
            <div className="item-link90">Home</div>
            <div className="item-link91">About</div>
            <div className="item-link92">Contact us</div>
            <div className="item-link93">{`Terms & Conditions`}</div>
            <div className="item-link94">Privacy Policy</div>
            <div className="item-link95">Corporate Social Responsibility</div>
          </div>
          <div className="divf-heading38">
            <div className="pseudo48" />
            <div className="our-company6">Our Company</div>
          </div>
          <div className="divf-heading39">
            <div className="pseudo48" />
            <div className="follow-us-on6">Follow us on</div>
          </div>
          <img className="vector-icon51" alt="" src="images/vector39.svg" />
          <img className="vector-icon52" alt="" src="images/vector40.svg" />
          <img className="component-1-icon2" alt="" src="images/component-12.svg" />
          <img className="vector-icon53" alt="" src="images/vector41.svg" />
        </div>
        <div className="divcol-xl-66">
          <div className="divf-heading40">
            <div className="pseudo48" />
            <div className="services6">Services</div>
          </div>
          <div className="divrow25">
            <div className="list41">
              <div className="item-link96">Money Transfer</div>
              <div className="item-link97">Aadhaar Banking</div>
              <div className="item-link98">Micro ATM</div>
              <div className="item-link99">Utility Payments</div>
            </div>
            <div className="list42">
              <div className="item-link100">Travel Services</div>
              <div className="item-link101">PAN Card</div>
              <div className="item-link102">ITR Filing</div>
            </div>
            <div className="divcol-md-66">
              <div className="list43">
                <div className="item-link103">API</div>
                <div className="item-link104">POS</div>
              </div>
              <div className="divf-heading41">
                <div className="pseudo48" />
                <div className="products6">Products</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFooter;
