import React, { useState } from "react";
import { useCallback } from "react";
import ContainerUtilities from "../components/ContainerUtilities";
import { useNavigate } from "react-router-dom";
import ProductContainer from "../components/ProductContainer";
import "./Utilities.css";
const Utilities = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  // State to control the visibility of list14
  const [isListVisible, setListVisible] = useState(false);

  // Function to toggle the visibility of list14
  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  return (
    <div className="utilities">
      <ContainerUtilities />
      <div className="divcopyright3">
        <i className="rnfi-services-pvt3">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
      <div className="header3">
        <div className="divnavbar-brand3">
          <img className="link-logopng3" alt="" src="images/link--logopng@2x.png" />
        </div>
        <div className={`nav3 ${isListVisible ? "display-block" : ""}`}>
          <img
            className="close-icon"
            src="images/close.png"
            alt="Close Sidebar"
            onClick={closeSidebar}
          />
          <div className="list3">
            <div className="item15">
              <i className="link-home3" onClick={onLinkHomeClick}>Home</i>
            </div>
            <div className="item16">
              <i className="link-about3" onClick={onLinkAboutClick}>
                About RNFI
              </i>
            </div>
            <div className="item17">
              <i className="link-home3">{`Products & Solutions`}</i>
              <div className="mega-menu">
                <div className="menu-column">
                  <h2 className="menu-heading">Products</h2>
                  <ul className="mega-menu-list">
                    <li><a href="aadhar-banking">Aadhar Card</a></li>
                    <li><a href="micro-atm">Micro ATM</a></li>
                    <li><a href="money-transfer">Money Transfer</a></li>
                  </ul>
                </div>
                <div className="menu-column">
                  <h2 className="menu-heading">Solutions</h2>
                  <ul className="mega-menu-list">
                    <li><a href="Utilities">Utilities</a></li>
                    <li><a href="travel-services">Travel</a></li>
                    <li><a href="itrfiling">ITRFiling</a></li>
                    <li><a href="pan-card-services">Pan Card</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="item18">
              <i className="link-about3" onClick={onLinkContactClick}>
                Contact Us
              </i>
            </div>
            <div className="item19">
              <div className="pseudo3" />
              <i className="link-login3">Login</i>
            </div>
          </div>
        </div>
        <div className="mobile-toggle3" onClick={toggleListVisibility}>
          <img className="hamburger" src="images/hamburger.png" alt="Toggle Menu" />
        </div>
      </div>
      <ProductContainer
        dimensionCode="vector7.svg"
        dimensionCodeText="vector8.svg"
        dimensionCodeImageUrl="vector9.svg"
        dimensionCodeText2="vector9.svg"
        dimensionCodeImageUrl2="vector10.svg"
        dimensionCodeImageUrl3="images/divsocialicon1.svg"
        dimensionCodeText3="vector11.svg"
        dimensionCodeText4="images/vector12.svg"
        dimensionCodeImageUrl4="component-11.svg"
        dimensionCodeText5="vector13.svg"
        pseudoTop="43.44em"
        pseudoLeft="-0.06em"
        pseudoHeight="27.56em"
        pseudoTop1="26.37%"
      />
    </div>
  );
};

export default Utilities;
