import ConnectWithUsContainer from "../components/ConnectWithUsContainer";
import Header from "../components/Header";
import FollowUsContainer from "../components/FollowUsContainer";
import "./ContactUs.css";
const ContactUs = () => {
  return (
    <div className="contact-us">
      <div className="frame10">
        <ConnectWithUsContainer />
        <Header />
        <FollowUsContainer itemLinkTop="45.38em" itemLinkHeight="unset" />
      </div>
      <div className="divcopyright7">
        <i className="rnfi-services-pvt7">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
    </div>
  );
};

export default ContactUs;
