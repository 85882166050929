import React, { useState } from "react";
import { useCallback } from "react";
import MoneyTransferForm from "../components/MoneyTransferForm";
import { useNavigate } from "react-router-dom";
import FollowUsContainer from "../components/FollowUsContainer";
import "./MoneyTransfer.css";
const MoneyTransfer = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);


  // State to control the visibility of list14
  const [isListVisible, setListVisible] = useState(false);

  // Function to toggle the visibility of list14
  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  return (
    <div className="money-transfer">
      <div className="frame9">
        <MoneyTransferForm />
        <div className="header5">
          <div className="divnavbar-brand5">
            <img className="link-logopng5" alt="" src="images/link--logopng@2x.png" />
          </div>
          <div className={`nav5 ${isListVisible ? "display-block" : ""}`}>
          <img
            className="close-icon"
            src="images/close.png"
            alt="Close Sidebar"
            onClick={closeSidebar}
          />
            <div className="list15">
              <div className="item31">
                <i className="link-home5" onClick={onLinkHomeClick}>Home</i>
              </div>
              <div className="item32">
                <i className="link-about5" onClick={onLinkAboutClick}>
                  About RNFI
                </i>
              </div>
              <div className="item33">
                <i className="link-home5">{`Products & Solutions`}</i>
                <div className="mega-menu">
                  <div className="menu-column">
                    <h2 className="menu-heading">Products</h2>
                    <ul className="mega-menu-list">
                      <li><a href="aadhar-banking">Aadhar Card</a></li>
                      <li><a href="micro-atm">Micro ATM</a></li>
                      <li><a href="money-transfer">Money Transfer</a></li>
                    </ul>
                  </div>
                  <div className="menu-column">
                    <h2 className="menu-heading">Solutions</h2>
                    <ul className="mega-menu-list">
                      <li><a href="Utilities">Utilities</a></li>
                      <li><a href="travel-services">Travel</a></li>
                      <li><a href="itrfiling">ITRFiling</a></li>
                      <li><a href="pan-card-services">Pan Card</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item34">
                <i className="link-about5" onClick={onLinkContactClick}>
                  Contact Us
                </i>
              </div>
              <div className="item35">
                <div className="pseudo17" />
                <i className="link-login5">Login</i>
              </div>
            </div>
          </div>
            <div className="mobile-toggle3" onClick={toggleListVisibility}>
              <img className="hamburger" src="images/hamburger.png" alt="Toggle Menu" />
            </div>
        </div>
        <FollowUsContainer />
      </div>
      <div className="divcopyright6">
        <i className="rnfi-services-pvt6">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
    </div>
  );
};

export default MoneyTransfer;