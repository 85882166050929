import "./ContainerCard.css";
const ContainerCard = () => {
  return (
    <div className="sectionsimple-way">
      <div className="background" />
      <div className="sectionsimple-way-child" />
      <div className="simply-submit">
        <div className="label-left-container">
          <p className="one-platform-for">One platform for all</p>
          <p className="one-platform-for">FinTech needs</p>
        </div>
        <div className="label-left">
          We have an emphatic presence in all the states of India with a
          deep-rooted network in urban, rural, and micro-rural DEHAT of BHARAT.
          The realm of agents spread across are well equipped and trained to
          serve the customers with every digital payment need.
        </div>
        <div className="button1">
          <div className="mission-vision">{`Mission & Vision`}</div>
          <img className="vector-icon55" alt="" src="images//vector43.svg" />
          <div />
        </div>
      </div>
    </div>
  );
};

export default ContainerCard;
