import React, { useState } from "react";
import { useCallback } from "react";
import AadhaarBankingForm from "../components/AadhaarBankingForm";
import { useNavigate } from "react-router-dom";
import "./AadharBanking.css";
const AadharBanking = () => {
  const navigate = useNavigate();
  const onLinkHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const onLinkAboutClick = useCallback(() => {
    navigate("/about-rnfi");
  }, [navigate]);

  const onLinkContactClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

   // State to control the visibility of list14
   const [isListVisible, setListVisible] = useState(false);

   // Function to toggle the visibility of list14
   const toggleListVisibility = () => {
     setListVisible(!isListVisible);
   };

   // Function to close the sidebar
  const closeSidebar = () => {
    setListVisible(false); // Set isListVisible to false to close the sidebar
  };

  return (
    <div className="aadhar-banking">
      <div className="frame4">
        <AadhaarBankingForm />
        <div className="footer1">
          <div className="divrow2">
            <div className="divcol-xl-32">
              <div className="frame5">
                <div className="divf-heading6">
                  <div className="pseudo10" />
                  <div className="head-office1">Head Office</div>
                </div>
                <div className="list9">
                  <div className="item23">
                    <img className="vector-icon5" alt="" src="images/vector18.svg" />
                    <div className="p1">
                      <div className="ug-5-MT-PORTAL-house-container2">
                        <span className="ug-5-MT-PORTAL-house-container3">
                          <p className="ug-5-MT-PORTAL-house1">
                            UG-5, MT-PORTAL House, Plot No. 42,
                          </p>
                          <p className="ug-5-MT-PORTAL-house1">
                            DLF Industrial Area, Kirti Nagar,
                          </p>
                          <p className="ug-5-MT-PORTAL-house1">
                            New Delhi - 110015, India
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="item24">
                    <img className="vector-icon6" alt="" src="images/vector19.svg" />
                    <div className="link-0111">011 4920 7777</div>
                  </div>
                  <div className="item25">
                    <img className="vector-icon7" alt="" src="images/vector20.svg" />
                    <div className="link-supportrnfiservicesco1">
                      support.com
                    </div>
                  </div>
                </div>
              </div>
              <div className="divhelp-desk1">
                <div className="divf-heading6">
                  <div className="pseudo10" />
                  <div className="sales-helpdesk1">Sales Helpdesk</div>
                </div>
                <div className="frame6">
                  <div className="link2">
                    <img className="vector-icon7" alt="" src="images/vector21.svg" />
                    <div className="salesrnfiservicescom1">
                      {" "}
                      sales.com
                    </div>
                  </div>
                  <div className="link3">
                    <img className="vector-icon9" alt="" src="images/vector22.svg" />
                    <div className="div1">8560 900 500</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divcol-xl-33">
              <div className="divf-heading6">
                <div className="pseudo10" />
                <div className="our-company1">Our Company</div>
              </div>
              <div className="list10">
                <div className="item-link15">Home</div>
                <div className="item-link16">About</div>
                <div className="item-link17">Contact us</div>
                <div className="item-link18">{`Terms & Conditions`}</div>
                <div className="item-link19">Privacy Policy</div>
                <div className="item-link20">
                  Corporate Social Responsibility
                </div>
              </div>
              <div className="divf-heading6">
                <div className="pseudo10" />
                <div className="follow-us-on1">Follow us on</div>
              </div>
              <img className="frame-icon1" alt="" src="images/frame1.svg" />
            </div>
            <div className="divcol-xl-61">
              <div className="divf-heading6">
                <div className="pseudo10" />
                <div className="services1">Services</div>
              </div>
              <div className="divrow3">
                <div className="frame7">
                  <div className="list11">
                    <div className="item-link21">Money Transfer</div>
                    <div className="item-link22">Aadhaar Banking</div>
                    <div className="item-link23">Micro ATM</div>
                    <div className="item-link24">Utility Payments</div>
                  </div>
                  <div className="divcol-md-61">
                    <div className="divf-heading11">
                      <div className="pseudo10" />
                      <div className="products1">Products</div>
                    </div>
                    <div className="list12">
                      <div className="item-link25">API</div>
                      <div className="item-link26">POS</div>
                    </div>
                  </div>
                </div>
                <div className="list13">
                  <div className="item-link27">Travel Services</div>
                  <div className="item-link28">PAN Card</div>
                  <div className="item-link29">ITR Filing</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame8">
        <div className="header4">
          <div className="divnavbar-brand4">
            <img
              className="link-logopng4"
              alt=""
              src="images/link--logopng2@2x.png"
            />
          </div>
          <div className={`nav4 ${isListVisible ? "display-block" : ""}`}>
          <img
            className="close-icon"
            src="images/close.png"
            alt="Close Sidebar"
            onClick={closeSidebar}
          />
            <div className="list14">
              <div className="item26">
                <i className="link-home4" onClick={onLinkHomeClick}>Home</i>
              </div>
              <div className="item27">
                <i className="link-about4" onClick={onLinkAboutClick}>
                  About RNFI
                </i>
              </div>
              <div className="menu-container">
                <div className="item28">
                  <i className="link-home4">{`Products & Solutions`}</i>
                  <div className="mega-menu">
                    <div className="menu-column">
                      <h2 className="menu-heading">Products</h2>
                      <ul className="mega-menu-list">
                        <li><a href="aadhar-banking">Aadhar Card</a></li>
                        <li><a href="micro-atm">Micro ATM</a></li>
                        <li><a href="money-transfer">Money Transfer</a></li>
                      </ul>
                    </div>
                    <div className="menu-column">
                      <h2 className="menu-heading">Solutions</h2>
                      <ul className="mega-menu-list">
                        <li><a href="Utilities">Utilities</a></li>
                        <li><a href="travel-services">Travel</a></li>
                        <li><a href="itrfiling">ITRFiling</a></li>
                        <li><a href="pan-card-services">Pan Card</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item29">
                <i className="link-about4" onClick={onLinkContactClick}>
                  Contact Us
                </i>
              </div>
              <div className="item30">
                <div className="pseudo16" />
                <i className="link-login4">Login</i>
              </div>
            </div>
          </div>
           <div className="mobile-toggle3" onClick={toggleListVisibility}>
              <img className="hamburger" src="images/hamburger.png" alt="Toggle Menu" />
            </div>
        </div>
      </div>
      <div className="divcopyright5">
        <i className="rnfi-services-pvt5">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
    </div>
  );
};

export default AadharBanking;
