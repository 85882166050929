import React, { useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContainerFrameMicroATM from "../components/ContainerFrameMicroATM";
import "./MicroATM.css";
const MicroATM = () => {
    const navigate = useNavigate();

    const onLinkAboutClick = useCallback(() => {
      navigate("/about-rnfi");
    }, [navigate]);
  
    const onLinkContactClick = useCallback(() => {
      navigate("/contact-us");
    }, [navigate]);
      
  // State to control the visibility of list14
  const [isListVisible, setListVisible] = useState(false);

  // Function to toggle the visibility of list14
  const toggleListVisibility = () => {
    setListVisible(!isListVisible);
  };
  return (
    <div className="micro-atm">
      <div className="frame">
        <ContainerFrameMicroATM />
      </div>
      <div className="footer">
          <div className="divrow">
            <div className="divcol-xl-3">
              <div className="frame1">
                <div className="divf-heading">
                  <div className="pseudo4" />
                  <div className="head-office">Head Office</div>
                </div>
                <div className="list4">
                  <div className="item20">
                    <img className="vector-icon" alt="" src="images/vector7.svg" />
                    <div className="p">
                      <div className="ug-5-MT-PORTAL-house-container">
                        <span className="ug-5-MT-PORTAL-house-container1">
                          <p className="ug-5-MT-PORTAL-house">
                            UG-5, MT-PORTAL House, Plot No. 42,
                          </p>
                          <p className="ug-5-MT-PORTAL-house">
                            DLF Industrial Area, Kirti Nagar,
                          </p>
                          <p className="ug-5-MT-PORTAL-house">
                            New Delhi - 110015, India
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="item21">
                    <img className="vector-icon1" alt="" src="images/vector14.svg" />
                    <div className="link-011">011 4920 7777</div>
                  </div>
                  <div className="item22">
                    <img className="vector-icon2" alt="" src="images/vector15.svg" />
                    <div className="link-supportrnfiservicesco">
                      support.com
                    </div>
                  </div>
                </div>
              </div>
              <div className="divhelp-desk">
                <div className="divf-heading">
                  <div className="pseudo4" />
                  <div className="sales-helpdesk">Sales Helpdesk</div>
                </div>
                <div className="frame2">
                  <div className="link">
                    <img className="vector-icon2" alt="" src="images/vector16.svg" />
                    <div className="salesrnfiservicescom">
                      {" "}
                      sales.com
                    </div>
                  </div>
                  <div className="link1">
                    <img className="vector-icon1" alt="" src="images/vector17.svg" />
                    <div className="div">8560 900 500</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divcol-xl-31">
              <div className="divf-heading">
                <div className="pseudo4" />
                <div className="our-company">Our Company</div>
              </div>
              <div className="list5">
                <div className="item-link">Home</div>
                <div className="item-link1">About</div>
                <div className="item-link2">Contact us</div>
                <div className="item-link3">{`Terms & Conditions`}</div>
                <div className="item-link4">Privacy Policy</div>
                <div className="item-link5">
                  Corporate Social Responsibility
                </div>
              </div>
              <div className="divf-heading">
                <div className="pseudo4" />
                <div className="follow-us-on">Follow us on</div>
              </div>
              <img className="frame-icon" alt="" src="images/frame.svg" />
            </div>
            <div className="divcol-xl-6">
              <div className="divf-heading4">
                <div className="pseudo4" />
                <div className="services">Services</div>
              </div>
              <div className="divrow1">
                <div className="frame3">
                  <div className="list6">
                    <div className="item-link6">Money Transfer</div>
                    <div className="item-link7">Aadhaar Banking</div>
                    <div className="item-link8">Micro ATM</div>
                    <div className="item-link9">Utility Payments</div>
                  </div>
                  <div className="divcol-md-6">
                    <div className="divf-heading">
                      <div className="pseudo4" />
                      <div className="products">Products</div>
                    </div>
                    <div className="list7">
                      <div className="item-link10">API</div>
                      <div className="item-link11">POS</div>
                    </div>
                  </div>
                </div>
                <div className="list8">
                  <div className="item-link12">Travel Services</div>
                  <div className="item-link13">PAN Card</div>
                  <div className="item-link14">ITR Filing</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="divcopyright4">
        <i className="rnfi-services-pvt4">
          © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
        </i>
      </div>
    </div>
  );
};

export default MicroATM;
