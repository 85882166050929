import ConnectWithUsContainer1 from "../components/ConnectWithUsContainer1";
import ProductFooter from "../components/ProductFooter";
import RevolutionaryFintechContainer from "../components/RevolutionaryFintechContainer";
import ContainerCard from "../components/ContainerCard";
import ServicesContainer from "../components/ServicesContainer";
import PartnerCard1 from "../components/PartnerCard1";
import Header from "../components/Header.js";
import "./Homepage.css";
const Homepage = () => {
  return (
    <div className="homepage">
      <div className="homepage1">
        <Header />
        <ProductFooter />
        <div className="divcopyright8">
          <div className="rnfi-services-pvt8">
            © 2021 MT-PORTAL PVT. LTD. ALL RIGHT RESERVED.
          </div>
        </div>
        <RevolutionaryFintechContainer />
        <ContainerCard />
        <ServicesContainer />
        <PartnerCard1 />
        <img className="combined-icon" alt="" src="images/combined.svg" />
      </div>
    </div>
  );
};

export default Homepage;
