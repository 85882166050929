import { useMemo } from "react";
import "./ContainerFooter.css";
const ContainerFooter = ({
  dimensionCode,
  dimensionCodeText,
  dimensionCodeImageUrl,
  dimensionCodeText2,
  dimensionCodeImageUrl2,
  dimensionCodeImageUrl3,
  dimensionCodeText3,
  dimensionCodeText4,
  dimensionCodeImageUrl4,
  dimensionCodeText5,
  salesrnfiservicescomZIndex,
  salesrnfiservicescomMarginTop,
  salesrnfiservicescomPosition,
  salesrnfiservicescomTop,
  salesrnfiservicescomLeft,
}) => {
  const footerStyle = useMemo(() => {
    return {
      zIndex: salesrnfiservicescomZIndex,
      marginTop: salesrnfiservicescomMarginTop,
      position: salesrnfiservicescomPosition,
      top: salesrnfiservicescomTop,
      left: salesrnfiservicescomLeft,
    };
  }, [
    salesrnfiservicescomZIndex,
    salesrnfiservicescomMarginTop,
    salesrnfiservicescomPosition,
    salesrnfiservicescomTop,
    salesrnfiservicescomLeft,
  ]);

  return (
    <div className="footer2" style={footerStyle}>
      <div className="divrow6">
        <div className="divcol-xl-34">
          <div className="list16">
            <div className="item37">
              <img className="vector-icon10" alt="" src={dimensionCode} />
              <div className="p3">
                <div className="ug-5-MT-PORTAL-house-container4">
                  <span className="ug-5-MT-PORTAL-house-container5">
                    <p className="ug-5-MT-PORTAL-house2">
                      UG-5, MT-PORTAL House, Plot No. 42,
                    </p>
                    <p className="ug-5-MT-PORTAL-house2">
                      DLF Industrial Area, Kirti Nagar,
                    </p>
                    <p className="ug-5-MT-PORTAL-house2">
                      New Delhi - 110015, India
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div className="item38">
              <img className="vector-icon11" alt="" src={dimensionCodeText} />
              <div className="link-0112">011 4920 7777</div>
            </div>
            <div className="item39">
              <img
                className="vector-icon12"
                alt=""
                src={dimensionCodeImageUrl}
              />
              <div className="link-supportrnfiservicesco2">
                support.com
              </div>
            </div>
          </div>
          <div className="divf-heading12">
            <div className="pseudo18" />
            <div className="head-office2">Head Office</div>
          </div>
          <div className="divhelp-desk2">
            <div className="link4">
              <img className="vector-icon13" alt="" src={dimensionCodeText2} />
              <div className="salesrnfiservicescom2">
                {" "}
                sales.com
              </div>
            </div>
            <div className="link5">
              <img
                className="vector-icon14"
                alt=""
                src={dimensionCodeImageUrl2}
              />
              <div className="div3">8560 900 500</div>
            </div>
            <div className="divf-heading13">
              <div className="pseudo18" />
              <div className="sales-helpdesk2">Sales Helpdesk</div>
            </div>
          </div>
        </div>
        <div className="divcol-xl-35">
          <div className="list17">
            <div className="item-link30">Home</div>
            <div className="item-link31">About</div>
            <div className="item-link32">Contact us</div>
            <div className="item-link33">{`Terms & Conditions`}</div>
            <div className="item-link34">Privacy Policy</div>
            <div className="item-link35">Corporate Social Responsibility</div>
          </div>
          <img className="divsocial-icon" alt="" src={dimensionCodeImageUrl3} />
          <div className="divf-heading14">
            <div className="pseudo18" />
            <div className="our-company2">Our Company</div>
          </div>
          <div className="divf-heading15">
            <div className="pseudo18" />
            <div className="follow-us-on2">Follow us on</div>
          </div>
          <img className="vector-icon15" alt="" src={dimensionCodeText3} />
          <img className="vector-icon16" alt="" src={dimensionCodeText4} />
          <img
            className="component-1-icon"
            alt=""
            src={dimensionCodeImageUrl4}
          />
          <img className="vector-icon17" alt="" src={dimensionCodeText5} />
        </div>
        <div className="divcol-xl-62">
          <div className="divf-heading16">
            <div className="pseudo18" />
            <div className="services2">Services</div>
          </div>
          <div className="divrow7">
            <div className="list18">
              <div className="item-link36">Money Transfer</div>
              <div className="item-link37">Aadhaar Banking</div>
              <div className="item-link38">Micro ATM</div>
              <div className="item-link39">Utility Payments</div>
            </div>
            <div className="list19">
              <div className="item-link40">Travel Services</div>
              <div className="item-link41">PAN Card</div>
              <div className="item-link42">ITR Filing</div>
            </div>
            <div className="divcol-md-62">
              <div className="list20">
                <div className="item-link43">API</div>
                <div className="item-link44">POS</div>
              </div>
              <div className="divf-heading17">
                <div className="pseudo18" />
                <div className="products2">Products</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerFooter;
