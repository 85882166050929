import "./FormContainer.css";
const FormContainer = () => {
  return (
    <div className="divrow5">
      <div className="divcol-sm-12">
        <div className="divbanner-text">
          <div className="nav-breadcrumb-ordered-lis">
            <div className="item-products">{`Products & Solutions`}</div>
            <div className="item36">
              <div className="item-products">/</div>
              <div className="itr-filing1">ITR Filing</div>
            </div>
          </div>
          <b className="heading-2">ITR Filing</b>
          <div className="p2">
            <i className="filing-itr-just-container">
              <p className="filing-itr-just">
                Filing ITR just got a little more convenient. MT-PORTAL’s platform
                now facilitates online
                ITR filing through an assisted model. Now customers can
                conveniently get their
                ITR filed at their closest MT-PORTAL retailer. To file their ITR,
                customers simply have to
                fill out their form with the relevant details and submit it to
                the merchant. After
                submitting the form, the customer will be shortly contacted by
                our associate who
                will personally guide them through the entire process.
              </p>
            </i>
          </div>
        </div>
      </div>
      <div className="divcol-sm-121">
        <img className="itrpng-icon" alt="" src="images/itrpng@2x.png" />
      </div>
    </div>
  );
};

export default FormContainer;
