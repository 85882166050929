import "./ContainerUtilities.css";
const ContainerUtilities = () => {
  return (
    <div className="section5">
      <div className="divrow12">
        <div className="divcol-sm-126">
          <div className="divbanner-text3">
            <div className="nav-breadcrumb-ordered-lis3">
              <div className="item-products3">{`Products & Solutions`}</div>
              <div className="item45">
                <div className="item-products3">/</div>
                <div className="utilities1">Utilities</div>
              </div>
            </div>
            <b className="heading-23">Utilities</b>
            <div className="p8">
              <i className="merchants-can-help-container">
                <p className="merchants-can-help">
                  Merchants can help their customers in paying their utility
                  bills and conduct mobile
                  recharge /DTH recharge by turning their shop into an easy and
                  accessible
                  recharges bill payment center, with the help of MT-PORTAL’s
                  platform.
                </p>
              </i>
            </div>
            <div className="p9">
              <i className="merchants-can-help-container">
                <p className="merchants-can-help">
                  Customers, especially in the rural areas can visit their
                  closest MT-PORTAL-associated
                  merchant and pay their Utility and other recurring bills
                  without having to stand in long queues.</p>
              </i>
            </div>
          </div>
        </div>
        <div className="divcol-sm-127">
          <img className="rechargepng-icon" alt="" src="images/rechargepng@2x.png" />
        </div>
      </div>
    </div>
  );
};

export default ContainerUtilities;
